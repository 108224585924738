@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);

@import "~bootstrap/scss/bootstrap.scss";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #eff0f4;
  font-family: Lato, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
