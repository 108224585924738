.qrcode-wrapper {
    padding: 10px;
}

#bcaHowTo {
    .accordion-wrapper {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-top: 1rem;
        margin-bottom: 1rem;
        .btn {
            display: block;
            width: 100%;
            text-align: left;
            font-weight: bold;
            color: black;
            text-decoration: none;
            svg {
                float: right;
            }
        }
    }
}

#loadingWrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    > .loader {
        position: absolute;
        top: 30%;
        padding: 20px;
        text-align: center;
    }
}

.va-number {
    font-size: 1.7em;
    @media only screen and (min-width: 375px) {
        font-size: 2em;
    }
}